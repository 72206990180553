import { useCallback } from 'react';
import { useAuth } from './useAuth';

const SERVER_ENDPOINT = process.env.REACT_APP_SERVER_ENDPOINT;

const useProjectActions = () => {
    const { authFetch } = useAuth();

    const deleteItem = useCallback(async (name) => {
        try {
            const response = await authFetch(`${SERVER_ENDPOINT}/projects/${name}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    }, [authFetch]);

    const mergeItem = useCallback(async (targetName, aliasName) => {
        try {
            const response = await authFetch(`${SERVER_ENDPOINT}/add-project-alias`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: targetName, alias: aliasName }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            await response.json();
            return true;
        } catch (error) {
            console.error('Error merging project:', error);
            return false;
        }
    }, [authFetch]);

    return { deleteItem, mergeItem };
};

export default useProjectActions;