import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useAuth } from './hooks/useAuth';
import { Close, DeleteOutline, Settings } from '@mui/icons-material';
import { usePrivy } from '@privy-io/react-auth';

function ChatUI() {
    const { authFetch } = useAuth();
    const [apiKey, setApiKey] = useState('');
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [thinking, setThinking] = useState(false);
    const [thinkingDots, setThinkingDots] = useState(' ');
    const [isOpen, setIsOpen] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [tempApiKey, setTempApiKey] = useState('');
    const { user } = usePrivy();

    const messagesEndRef = React.useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        let interval;
        if (thinking) {
            interval = setInterval(() => {
                setThinkingDots(dots => (dots.length >= 4 ? ' ' : dots + '.'));
            }, 200);
        } else {
            setThinkingDots(' ');
        }
        return () => clearInterval(interval);
    }, [thinking]);

    useEffect(() => {
        const savedApiKey = localStorage.getItem('openRouterApiKey');
        if (savedApiKey) {
            setApiKey(savedApiKey);
            setTempApiKey(savedApiKey);
        }
    }, []);

    const handleOpenSettings = () => {
        setOpenSettings(true);
        setTempApiKey(apiKey);
    };

    const handleCloseSettings = () => {
        setOpenSettings(false);
    };

    const handleSaveSettings = () => {
        setApiKey(tempApiKey);
        localStorage.setItem('openRouterApiKey', tempApiKey);
        setOpenSettings(false);
    };

    const handleApiKeyChange = (e) => {
        setTempApiKey(e.target.value);
    };

    const handleSendMessage = () => {
        if (inputMessage.trim()) {
            sendMessage(inputMessage);
        }
    };

    const sendMessage = async (text) => {
        if (apiKey) {
            const userMessage = { role: 'user', content: text, type: 'text' };
            setMessages(prevMessages => [...prevMessages, userMessage]);
            setInputMessage('');
            setThinking(true);

            try {
                const response = await authFetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/chat`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userMessage: text, conversationHistory: messages, apiKey }),
                });

                if (!response.ok) throw new Error('Failed to get response');

                const data = await response.json();

                if (data && data.message) {
                    const assistantMessage = { role: 'assistant', content: data.message, type: 'text' };
                    setMessages(prevMessages => [...prevMessages, assistantMessage]);
                } else {
                    console.error('Invalid response format:', data);
                }
            } catch (error) {
                console.error('Error calling chat API:', error);
                setMessages(prevMessages => [...prevMessages, { role: 'system', content: 'Error: Failed to get response. Please check your API key and try again.', type: 'text' }]);
            } finally {
                setThinking(false);
            }
        } else {
            setOpenSettings(true)
        }
    };

    const renderMessageContent = (content, role) => {
        const prefix = role === 'user' ? `<${user?.wallet?.address.substring(0, 4)}...${user?.wallet?.address.substring(38, 42)}>` : '<aixbt>';

        return (
            <>
                <Typography component="span">
                    {prefix}
                </Typography>
                <br /> {/* Break line after the prefix */}
                {content.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        <Typography component="span">
                            {line}
                        </Typography>
                        {index < content.split('\n').length - 1 && <br />}
                    </React.Fragment>
                ))}
            </>
        );
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    // Handle closing and clearing chat
    const handleToggleOpen = () => setIsOpen(!isOpen);
    const handleClearChat = () => setMessages([]);

    return (
        <Box sx={{
            position: 'fixed',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxHeight: '80vh',
            maxWidth: '840px',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #494949',
            borderBottom: 'none',
            background: "#363633",
            borderTopRightRadius: { xs: '0', sm: '10px' },
            borderTopLeftRadius: { xs: '0', sm: '10px' },
            zIndex: 1000,
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: isOpen ? '80vh' : 'auto',
                p: 2,
            }}>
                {isOpen && messages.length > 0 && (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={handleClearChat} >
                                <DeleteOutline />
                            </IconButton>
                            <IconButton onClick={handleToggleOpen}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            maxHeight: 'calc(80vh - 120px)', // Account for input and padding
                            borderBottom: '1px solid #484848',
                            marginBottom: '10px',
                            paddingBottom: '10px',
                        }}>
                            {messages.map((message, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        m: 1,
                                        p: 1,
                                        lineHeight: 1,
                                        color: message.role === 'user' ? 'inherit' : '#8dc6f4',
                                    }}
                                >
                                    {renderMessageContent(message.content, message.role)}
                                </Box>
                            ))}
                            {thinking && (
                                <Box sx={{ m: 1, p: 1, color: '#8dc6f4', height: '20px', width: '20px' }}>
                                    <Typography variant="body2">{thinkingDots}</Typography>
                                </Box>
                            )}
                            <div ref={messagesEndRef} />
                        </Box>
                    </>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Type to ask aixbt..."
                        value={inputMessage}
                        onFocus={() => setIsOpen(true)}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        sx={{
                            flex: 1,
                            '& .MuiOutlinedInput-root': {
                                color: 'white',
                                '& fieldset': {
                                    border: 'none',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: 'white',
                                opacity: 0.8,
                            },
                        }}
                    />
                    <IconButton
                        onClick={handleOpenSettings}
                        sx={{
                            color: 'white',
                            opacity: 0.8,
                            '&:hover': {
                                opacity: 1
                            }
                        }}
                    >
                        <Settings />
                    </IconButton>
                </Box>
                <Dialog
                    open={openSettings}
                    onClose={handleCloseSettings}
                    PaperProps={{
                        sx: {
                            backgroundColor: '#363633',
                            color: 'white',
                        }
                    }}
                >
                    <DialogTitle>Settings</DialogTitle>
                    <DialogContent>
                        <div style={{ marginBottom: '20px' }}>
                            Sign up for an API key on <a href="https://openrouter.ai">OpenRouter</a> to access aixbt alpha datasets.<br /><br />
                            You can fund your account using crypto, and each interaction with aixbt will cost approximately $0.05.
                        </div>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="OpenRouter API Key"
                            type="password"
                            fullWidth
                            variant="outlined"
                            value={tempApiKey}
                            onChange={handleApiKeyChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.5)',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        />
                    </DialogContent>
                    <DialogActions sx={{ padding: 2 }}>
                        <Button onClick={handleCloseSettings} sx={{ color: 'white' }}>
                            Cancel
                        </Button>
                        <Button onClick={handleSaveSettings} variant="contained">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* <TextField
                        size="small"
                        variant="outlined"
                        placeholder="OpenRouter API Key"
                        value={apiKey}
                        onChange={handleApiKeyChange}
                        type="password"
                        sx={{
                            width: '30%',
                            '& .MuiOutlinedInput-root': {
                                color: 'white',
                                '& fieldset': {
                                    borderColor: 'rgba(255, 255, 255, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(255, 255, 255, 0.5)',
                                },
                            },
                            '& .MuiInputBase-input::placeholder': {
                                color: 'rgba(255, 255, 255, 0.5)',
                            },
                        }}
                    /> */}
            </Box>
        </Box>
    );
}

export default ChatUI;