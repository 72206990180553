import React from 'react';
import { usePrivy } from '@privy-io/react-auth';

const LoginButton = () => {
  const { login, logout, user } = usePrivy();

  if (!user) {
    return (
      <a
        style={styles.button}
        onClick={login}
      >
        Connect
      </a>
    );
  }

  return (
    <a
      style={styles.button}
      onClick={logout}
    >
      {user?.wallet?.address.substring(0, 4)}...{user?.wallet?.address.substring(38, 42)}
    </a>
  );
};

const styles = {
  button: {
    flex: 1,
    cursor: 'pointer',
    textAlign: 'right',
    color: 'inherit',
    marginLeft: '10px',
    marginRight:'10px',
    padding:'10px 0'
  }
}

export default LoginButton;