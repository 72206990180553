import React, { useState, useEffect } from 'react';
import ProjectsListView from './ProjectsListView';
import ChatUI from './chatUI';
import LoginButton from './LoginButton';
import { DataProvider, useDataContext } from './DataContext';
import { Box, Typography, CircularProgress } from '@mui/material';
import ProjectControls from './ProjectControls';
import { usePrivy } from '@privy-io/react-auth';

function AppContent() {
  const { authenticated, ready } = usePrivy();
  const { error, loading, fetchData, data } = useDataContext();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (ready && authenticated) fetchData();
  }, [fetchData, ready, authenticated]);

  return (
    <Box sx={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 1 }}>
        <Typography variant="h6" sx={{ flex: 1, mr: 2, ml: 1, display: { xs: 'none', sm: 'block' } }}>aixbt</Typography>
        {authenticated && !error && !loading ? (
          <ProjectControls searchTerm={searchTerm} setSearchTerm={setSearchTerm} data={data} />
        ) : (
          <Typography color="error">{error}</Typography>
        )}
        <LoginButton />
      </Box>

      {loading ? (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          width: '100%'
        }}>
          <CircularProgress />
        </Box>
      ) : (
        authenticated && !error && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1, width: '100%' }}>
              <ProjectsListView searchTerm={searchTerm} />
            </Box>
            <ChatUI />
          </>
        )
      )}
    </Box>
  );
}

function App() {
  return (
    <DataProvider>
      <Box sx={{
        width: '100%',
        backgroundImage: 'url("/sketch.png")',
        backgroundSize: '50% auto',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
        <AppContent />
      </Box>
    </DataProvider>
  );
}

export default App;
