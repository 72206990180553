import { useState, useEffect, useRef, useCallback, useMemo } from 'react';

const useFilteredItems = (items, searchTerm) => {
    const [filteredItems, setFilteredItems] = useState([]);
    const workerRef = useRef();

    // Memoize filter parameters to prevent unnecessary worker calls
    const filterParams = useMemo(() => ({
        items,
        searchTerm: searchTerm.toLowerCase(),
    }), [items, searchTerm]);

    // Initialize web worker
    useEffect(() => {
        workerRef.current = new Worker(new URL('../workers/filterWorker.js', import.meta.url));
        
        workerRef.current.onmessage = (e) => {
            setFilteredItems(e.data);
        };

        return () => {
            workerRef.current.terminate();
        };
    }, []);

    // Update filtered items whenever the source data or filters change
    useEffect(() => {
        if (workerRef.current) {
            workerRef.current.postMessage(filterParams);
        }
    }, [filterParams]);

    // Simple update function for manual updates if needed
    const updateFilteredItems = useCallback((updateFn) => {
        setFilteredItems(prevItems => updateFn(prevItems));
    }, []);

    return [filteredItems, updateFilteredItems];
};

export default useFilteredItems;