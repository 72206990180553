import React, { useState, useCallback, useMemo } from 'react';
import { IconButton, Autocomplete, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import MergeIcon from '@mui/icons-material/Merge';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';

const CustomIconButton = ({ onClick, Icon, label }) => (
    <IconButton
        onClick={onClick}
        size="small"
        aria-label={label}
        style={{ color: 'rgba(255, 255, 255, 0.3)' }}
    >
        <Icon />
    </IconButton>
);

const ConfirmDialog = ({ open, onClose, title, content, onConfirm, confirmText }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onConfirm} autoFocus>{confirmText}</Button>
        </DialogActions>
    </Dialog>
);

const ProjectItemHeader = React.memo(({
    name,
    score,
    setMergeTarget,
    mergeItem,
    deleteItem,
    data,
    isAdmin
}) => {
    const [confirmDialog, setConfirmDialog] = useState({ open: false, type: null, targetProject: null });
    const [mergeDialogOpen, setMergeDialogOpen] = useState(false);

    const closeDialogs = useCallback(() => {
        setConfirmDialog({ open: false, type: null, targetProject: null });
        setMergeDialogOpen(false);
    }, []);

    const mergeOptions = useMemo(() =>
        data.aliases.filter(project => project.name !== name).map(project => project.name),
        [data.aliases, name]
    );

    const handleMergeChange = useCallback((event, newValue) => {
        if (newValue) {
            setMergeDialogOpen(false);
            setConfirmDialog({ open: true, type: 'merge', targetProject: newValue });
        }
    }, []);

    const handleMergeButtonClick = useCallback(() => {
        setMergeDialogOpen(true);
    }, []);

    const handleConfirmAction = useCallback(() => {
        if (confirmDialog.type === 'delete') {
            deleteItem(name);
        } else if (confirmDialog.type === 'merge') {
            mergeItem(confirmDialog.targetProject, name);
        }
        closeDialogs();
        setMergeTarget(null);
    }, [confirmDialog, deleteItem, mergeItem, name, setMergeTarget, closeDialogs]);

    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                padding: '12px 15px 0px 15px',
                width: '100%',
                boxSizing: 'border-box'
            }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <div style={{
                        backgroundColor: "#292927",
                        borderRadius: '3px',
                        marginRight: '8px',
                        fontSize: "13px",
                        fontWeight: "bold",
                        padding: '2px 5px'
                    }}>
                        {Math.round(score * 100)}
                    </div>
                    <a
                        target='_blank'
                        rel="noreferrer"
                        style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            marginRight: '8px',
                            display: 'inline-block',
                            textTransform: 'capitalize'
                        }}
                    >
                        <b style={{ display: 'inline-flex', alignItems: 'center' }}>{name}</b>
                    </a>
                </div>
                {/* <CustomIconButton onClick={() => hideItem(name)} Icon={VisibilityOffIcon} label="hide item" /> */}
                {isAdmin && (
                    <>
                        <CustomIconButton onClick={handleMergeButtonClick} Icon={MergeIcon} label="merge item" />
                        <CustomIconButton onClick={() => setConfirmDialog({ open: true, type: 'delete', targetProject: null })} Icon={DeleteIcon} label="delete item" />
                    </>
                )}
            </div>

            <Dialog
                open={mergeDialogOpen}
                onClose={() => setMergeDialogOpen(false)}
                aria-labelledby="merge-dialog-title"
            >
                <DialogTitle id="merge-dialog-title">Select Project to Merge Into</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={mergeOptions}
                        onChange={handleMergeChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                placeholder="Select to merge into"
                                InputProps={{
                                    ...params.InputProps,
                                    style: { fontSize: '0.8rem', padding: '2px 4px' }
                                }}
                            />
                        )}
                        style={{ width: 300, marginTop: '10px' }}
                        ListboxProps={{ style: { fontSize: '0.8rem' } }}
                        autoComplete
                        autoHighlight
                        openOnFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setMergeDialogOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <ConfirmDialog
                open={confirmDialog.open}
                onClose={closeDialogs}
                title={confirmDialog.type === 'delete' ? "Confirm Deletion" : "Confirm Merge"}
                content={confirmDialog.type === 'delete'
                    ? `Permanently delete ${name}? This affects all users.`
                    : `Permanently merge ${name} into ${confirmDialog.targetProject}? This affects all users.`}
                onConfirm={handleConfirmAction}
                confirmText={confirmDialog.type === 'delete' ? 'Delete' : 'Merge'}
            />
        </>
    );
});

export default ProjectItemHeader;