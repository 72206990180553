import { useState, useEffect, useRef } from 'react';

const useWebSocket = () => {
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);
    const wsRef = useRef(null);

    useEffect(() => {
        // Only create connection if it doesn't exist
        if (wsRef.current?.readyState === WebSocket.OPEN) return;

        const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

        ws.onopen = () => {
            console.log('WebSocket connected');
            setIsConnected(true);
            
            // Subscribe to summaries with insert-only
            ws.send(JSON.stringify({ 
                type: 'subscribe', 
                topics: ['summaries'],
                options: {
                    operationTypes: ['insert']
                }
            }));

            // Subscribe to projects with all operations
            ws.send(JSON.stringify({ 
                type: 'subscribe', 
                topics: ['projects']
                // No operationTypes specified = all operations
            }));
        };

        ws.onerror = () => {
            setError('WebSocket connection error');
            setIsConnected(false);
        };

        ws.onclose = () => {
            setIsConnected(false);
            wsRef.current = null;  // Clear the ref when connection closes
        };

        wsRef.current = ws;

        return () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        };
    }, []); // Empty dependency array - only run once on mount

    return {
        isConnected,
        error,
        ws: wsRef.current
    };
};

export default useWebSocket;