// DataContext.js
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useAuth } from './hooks/useAuth';
import useWebSocket from './hooks/useWebSocket';

const DataContext = createContext(null);

export function DataProvider({ children }) {
    const { isConnected, ws } = useWebSocket();
    const { authFetch } = useAuth();

    const [sortBy, setSortBy] = useState('score');
    const [state, setState] = useState({
        data: { projects: [], aliases: [] },
        loading: false,
        error: null
    });

    const fetchData = useCallback(async () => {
        console.log('fetching data');

        setState(prev => ({ ...prev, loading: true, error: null }));

        try {
            const [summariesResponse, projectsResponse] = await Promise.all([
                authFetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/summaries`),
                authFetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/projects`)
            ]);

            if (!summariesResponse.ok) {
                const errorDetails = await summariesResponse.json();
                throw new Error(`${errorDetails.error || 'Unknown error'}`);
            }

            if (!projectsResponse.ok) {
                const errorDetails = await projectsResponse.json();
                throw new Error(`${errorDetails.error || 'Unknown error'}`);
            }

            const summariesData = await summariesResponse.json();
            const projectsArray = await projectsResponse.json();

            // Process the data
            const processedData = processData(summariesData, projectsArray);
            updateStateWithSortedProjects(processedData);
        } catch (error) {
            console.error('Error fetching data:', error);
            setState(prev => ({ ...prev, error: error.message, loading: false }));
        }
    }, [authFetch]);

    const processData = (summariesData, projectsArray) => {
        // Process summaries data
        const processedData = Object.entries(summariesData).reduce((acc, [projectName, dateData]) => {
            const lowerProjectName = projectName.toLowerCase();
            acc[lowerProjectName] = {
                name: lowerProjectName,
                dates: dateData
            };
            return acc;
        }, {});

        // Merge with projects data
        projectsArray.forEach(project => {
            const name = project.name.toLowerCase();
            if (processedData[name]) {
                processedData[name]._id = project._id;
                processedData[name].score = project.score;
                processedData[name].score_weekly = project.score_weekly;
            }
        });

        return {
            projects: Object.values(processedData),
            aliases: projectsArray
        };
    };

    const updateStateWithSortedProjects = (data, sortKey = sortBy) => {
        setState(prev => ({
            ...prev,
            data: {
                projects: sortProjects(data.projects, sortKey),
                aliases: data.aliases
            },
            loading: false,
            error: null
        }));
    };

    const sortProjects = (projects, sortBy) => {
        return [...projects].sort((a, b) => {
            if (sortBy === 'score_weekly') {
                return (b.score_weekly || 0) - (a.score_weekly || 0);
            } else {
                return (b.score || 0) - (a.score || 0);
            }
        });
    };


    const updateData = useCallback((updateFn) => {
        setState(prev => {
            const updatedData = updateFn(prev.data);
            return {
                ...prev,
                data: {
                    ...updatedData,
                    projects: sortProjects(updatedData.projects, sortBy)
                }
            };
        });
    }, [sortBy]);

    useEffect(() => {
        if (!ws) return;
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            const updatedIds = [], deletedIds = [], insertedProjects = [];
            const updatedProjects = {}, insertedSummaries = {};

            message.data.forEach(item => {
                if (message.topic === 'projects') {
                    const { operationType, _id, document } = item;
                    if (operationType === 'delete') deletedIds.push(_id);
                    else if (operationType === 'update') {
                        updatedProjects[_id] = document;
                        updatedIds.push(_id);
                    } else if (operationType === 'insert') insertedProjects.push(document);
                } else if (message.topic === 'summaries' && item.operationType === 'insert') {
                    const { projectName: name, date, description, tweets, clusters, _id } = item.document;
                    if (!insertedSummaries[name]) insertedSummaries[name] = {};
                    insertedSummaries[name][date] = { description, tweets: tweets.map(x => ({ id: x._id, avatar: x.user_info.avatar })), _id, clusters: clusters.length };
                }
            });

            updateData(prevData => {
                const existingNames = prevData.projects.map(p => p.name);
                const newNames = Object.keys(insertedSummaries).filter(name => !existingNames.includes(name));

                const newProjects = prevData.aliases
                    .filter(a => newNames.includes(a.name))
                    .map(a => ({ ...a, dates: insertedSummaries[a.name] }));

                return {
                    ...prevData,
                    projects: prevData.projects
                        .filter(p => !deletedIds.includes(p._id))
                        .map(p => {
                            const updated = updatedProjects[p._id] ? { ...updatedProjects[p._id], dates: p.dates } : p;
                            return insertedSummaries[p.name] ? { ...updated, dates: { ...updated.dates, ...insertedSummaries[p.name] } } : updated;
                        }).concat(newProjects),
                    aliases: prevData.aliases
                        .filter(a => !deletedIds.includes(a._id))
                        .map(a => updatedProjects[a._id] || a)
                        .concat(insertedProjects),
                };
            });
        };
    }, [ws]);
    
    const changeSortBy = (newSortBy) => {
        setSortBy(newSortBy);
    };

    useEffect(() => {
        // Re-sort projects whenever sortBy changes
        updateStateWithSortedProjects(state.data);
    }, [sortBy]);


    const value = {
        ...state,
        fetchData,
        updateData,
        sortBy,
        changeSortBy,
        isConnected,
        ws
    };

    return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export function useDataContext() {
    const context = useContext(DataContext);
    if (context === null) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
}