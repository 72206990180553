import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

// Move color scale logic to be shared between components
const colors = [
  '#90caf9', // Light blue
  '#a5bffb', // Light blue-violet
  '#b0b3fd', // Light purple-blue
  '#b89afc', // Light lilac
  '#c084fc', // Soft purple
  '#d291fb', // Bright lavender
  '#e1a7fa', // Light pinkish-purple
];

const getColorScale = () => {
  const clusterScale = d3.scaleSymlog()
    .domain([0, 40])
    .range([0, colors.length - 1]);

  return (clusterValue) => {
    if (!clusterValue) return '#2e2e2b';
    const colorIndex = Math.min(
      Math.floor(clusterScale(clusterValue)),
      colors.length - 1
    );
    return colors[colorIndex];
  };
};

const D3Timeline = ({ project }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (!project || !svgRef.current) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous render

    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const width = svg.node().getBoundingClientRect().width;
    const height = 6;

    const g = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Generate 72 hourly intervals for the last 3 days
    const now = new Date();
    const lastHour = d3.timeHour.offset(now, -1);
    const hours = d3.timeHour.range(
      d3.timeHour.offset(lastHour, -72), // 72 hours ago
      d3.timeHour.offset(lastHour, 0)    // up to last hour
    );

    // Scale for x-axis with padding
    const x = d3.scaleBand()
      .range([0, width])
      .padding(0.05) // Add 10% padding between segments
      .domain(hours.map(d => d.toISOString()));

    const colorScale = getColorScale();


    // Create rectangles for each hour with rounded corners
    g.selectAll(".bar")
      .data(hours)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", d => x(d.toISOString()))
      .attr("y", 0)
      .attr("width", x.bandwidth())
      .attr("height", height)
      .attr("rx", 0) // Add slight rounded corners
      .attr("fill", d => {
        const dateHourKey = d.toISOString();
        const entry = project.dates[dateHourKey];
        return colorScale(entry?.clusters || 0);
      });

  }, [project]);

  return (
    <svg ref={svgRef} width="100%" height={6}></svg>
  );
};

const formatTimestamp = (date) => {
  const entryDate = new Date(date);

  // Format as "Wed, 14:30h"
  return entryDate.toLocaleDateString(undefined, {
    weekday: 'short',
    hour: 'numeric',
    hour12: true, // Use 24-hour format
  });
};

const ProjectItemDetail = ({ project }) => {
  return (
    <div style={{ flex: 1, fontSize: "14px", margin: '5px 15px 10px 15px', paddingBottom: '10px' }}>
      <D3Timeline project={project} />
      {Object.entries(project.dates).reverse().map(([hourString, entry]) => (
        <div key={`${entry.summary_id}_${hourString}`} className="mb-2">
          <div style={{ marginTop: '5px', display: 'flex' }}>
            <span style={{ marginRight: '5px', opacity: 0.5, alignSelf: 'center', flex: 1 }}>{formatTimestamp(hourString)}</span>
          </div>
          <div style={{ display: "flex", justifyContent: 'space-between' }}>
            <div>
              {entry.description}
            </div>
            <div style={{ textAlign: 'right' }}>
              {entry.tweets?.map((t, tweetIndex) => (
                <a
                  key={t.id}
                  href={`https://x.com/user/status/${t.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-1"
                  style={{
                    alignSelf: 'center',
                    overflow: 'hidden',
                    display: 'inline-block',
                    width: '20px',
                    height: '20px',
                    background: '#2c2c2c',
                    borderRadius: '5px',
                    marginLeft: '3px'
                  }}
                >
                  <img src={t.avatar} style={{ width: '100%', filter: 'grayscale(70%)' }}
                    onMouseOver={(e) => e.currentTarget.style.filter = 'grayscale(0%)'}
                    onMouseOut={(e) => e.currentTarget.style.filter = 'grayscale(70%)'} />
                </a>
              ))}</div></div>
        </div>
      ))}
    </div>
  );
};


export default ProjectItemDetail;