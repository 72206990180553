import { usePrivy } from '@privy-io/react-auth';
import { useCallback, useState, useEffect } from 'react';

const ADMIN_ADDRESS = "0";//"0x8DFb37AaE4f8fCbD1f90015A9e75b48F50Fd9f59";

export function useAuth() {
  const { ready, authenticated, getAccessToken, user } = usePrivy();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user?.linkedAccounts?.[0]?.address) {
      const userAddress = user.linkedAccounts[0].address.toLowerCase();
      console.log(userAddress);
      setIsAdmin(userAddress === ADMIN_ADDRESS.toLowerCase());
    } else {
      setIsAdmin(false);
    }
  }, [user]);

  const authFetch = useCallback(async (url, options = {}) => {
    const token = await getAccessToken();

    const authOptions = {
      ...options,
      headers: {
        ...options.headers,
        'x-privy-token': token,
      },
    };

    const response = await fetch(url, authOptions);

    return response;
  }, []);

  return {
    authFetch,
    authenticated,
    ready,
    isAdmin
  };
}