import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { VariableSizeList as VirtualizedList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ProjectItem from './ProjectItem';

import { useAuth } from './hooks/useAuth';
import { useDataContext } from './DataContext';
import useFilteredItems from './hooks/useFilteredItems';
import useProjectActions from './hooks/useProjectActions';

const ProjectsListView = ({ searchTerm }) => {
    const { data, sortBy } = useDataContext();
    const { isAdmin } = useAuth();
    const [mergeTarget, setMergeTarget] = useState(null);
    const autocompleteRef = useRef(null);
    const listRef = useRef();
    const sizeMap = useRef({});

    const items = useMemo(() => data.projects || [], [data.projects]);
    const [filteredItems] = useFilteredItems(items, searchTerm);

    const { deleteItem, mergeItem } = useProjectActions();

    useEffect(() => {
        if (mergeTarget && autocompleteRef.current) {
            const input = autocompleteRef.current.querySelector('input');
            if (input) {
                setTimeout(() => {
                    input.focus();
                }, 0);
            }
        }
    }, [mergeTarget]);

    const getItemSize = index => {
        return sizeMap.current[index] || 250;
    };

    return (
        <>
            <Box sx={{ flexGrow: 1, px: 1 }}>
                <AutoSizer>
                    {({ height, width }) => (
                        <VirtualizedList
                            ref={listRef}
                            height={height}
                            itemCount={filteredItems.length}
                            itemSize={getItemSize}
                            width={width}
                            className="custom-scrollbar"
                        >
                            {({ index, style }) => (
                                <ProjectItem
                                    index={index}
                                    style={style}
                                    item={filteredItems[index]}
                                    mergeTarget={mergeTarget}
                                    setMergeTarget={setMergeTarget}
                                    mergeItem={mergeItem}
                                    deleteItem={deleteItem}
                                    autocompleteRef={autocompleteRef}
                                    data={data}
                                    sizeMap={sizeMap}
                                    listRef={listRef}
                                    isAdmin={isAdmin}
                                    sortBy={sortBy}
                                />
                            )}
                        </VirtualizedList>
                    )}
                </AutoSizer>
            </Box>
        </>
    );
};

export default ProjectsListView;