import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { PrivyProvider } from '@privy-io/react-auth';

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9', // Customize your primary color
    },
    secondary: {
      main: '#f48fb1', // Customize your secondary color
    },
    background: {
      default: '#2e2e2b',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#fcfcfc', // Adjust the default text color to a light gray
      secondary: '#999999', // Adjust secondary text color to a muted gray
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000', // Set AppBar background color to #1c1c1c
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#c3ffe3', // Color of the switch when it's off
          '&.Mui-checked': {
            color: '#c3ffe3', // Color of the switch when it's on
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1e1e1e', // Set dialog background color
          backgroundImage: 'none', // Remove the overlay
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: '#c0c0c0', // Default color for pagination items
          '&.Mui-selected': {
            backgroundColor: '#c3ffe3', // Background color for selected page
            '&:hover': {
              backgroundColor: '#96ccb3', // Hover color for selected page
            },
          },
        },
      },
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={darkTheme}>
    {/* CssBaseline helps apply global styles for the dark theme */}
    <CssBaseline />
    <PrivyProvider
      appId={process.env.REACT_APP_PRIVY_APP_ID}
      config={{
        loginMethods: ['wallet'],
        appearance: {
          theme: 'dark',
          accentColor: '#676FFF',
        },
      }}
    >
      <App />
    </PrivyProvider>
  </ThemeProvider>
);
