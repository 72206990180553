import React from 'react';
import { Box, TextField, Select, MenuItem } from '@mui/material';
import { useDataContext } from './DataContext';

const ProjectControls = ({ searchTerm, setSearchTerm, data }) => {
    const { sortBy, changeSortBy } = useDataContext();

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSortChange = (event) => {
        changeSortBy(event.target.value);
    };

    return (
        <Box style={{ width: '800px', padding: '5px 0', display: 'flex', alignItems: 'center' }}>
            <TextField
                variant="outlined"
                size="small"
                sx={{ flex: 1, mr: 1 }}
                placeholder={`Search ${data.projects.length} trending projects...`}
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <Select
                value={sortBy}
                onChange={handleSortChange}
                variant="outlined"
                size="small"
            >
                <MenuItem value="score">3 Days Trend</MenuItem>
                <MenuItem value="score_weekly">7 Days Trend</MenuItem>
            </Select>
        </Box>
    );
};

export default ProjectControls;
